import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import AutoSuggest, { ChangeEvent } from 'react-autosuggest';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { Field, Form } from 'react-jeff';
import { checkValidAddress, AddressService, AddressLookup, NullableAddressLookup } from '@sky-tv-group/shared';

interface IValidatedAddressSuggest {
  addressField: Field<NullableAddressLookup, string>;
  formValidation: Form<string>;
  placeholder?: string;
  id: string;
  className: string;
  disabled?: boolean;
  addressType: string;
  addressService: AddressService;
  excludeExpression?: string;
  resetInstallationAddressField?:()=>void;
  selectedAddress?:string;
}

/**
 * Common address field that uses the address service + react-autosuggest + react-jeff for address auto suggest with validation
 */
const ValidatedAddressSuggest: React.FunctionComponent<IValidatedAddressSuggest> = ({
  addressField,
  formValidation,
  placeholder,
  id,
  className,
  disabled,
  addressType,
  addressService,
  excludeExpression,
  resetInstallationAddressField,
  selectedAddress
}) => {
  // state
  const [searchResults, setSearchResults] = useState<AddressLookup[]>([]);

  const [searchData, setSearchData] = useState(0);
  const [selectedValidAddress, setSelectedValidAddress] = useState<NullableAddressLookup>();
  // error validation check
  const errorPresent = addressField.errors.length > 0;

  // classnames
  const inputClassName = cx(
    'sky-input sky-input--text sky-h7-reg',
    { 'bg-gray-light': disabled },
    { 'sky-input--error': errorPresent && !disabled },
    className
  );
  const errorClassName = cx(className, { 'text-error': errorPresent && !disabled });

  // methods used by autosuggest

  const getSuggestions = async (value: string): Promise<AddressLookup[]> => {
    const suggestions = await addressService.getAddress(value, addressType);
    // only take the top 5 results from the address data
    let filter = (value: AddressLookup, index: number, array: AddressLookup[]) => true;
    if (excludeExpression) {
      let excludeRegExp = new RegExp(excludeExpression, 'i');
      filter = (value, index, array) => !excludeRegExp.test(value.label);
    }
    return suggestions.filter(filter).slice(0, 5);
  };

  const getSuggestionValue = (address: AddressLookup) => {
    return address.label;
  };

  const renderSuggestions = (address: AddressLookup) => <div id={address.id}>{address.label}</div>;

  // update addressField validations
  // Don't execute on down and up arrow keys.
  const onChange = (_event: FormEvent<any>, params: ChangeEvent) => {
    setSearchData((st)=>st+1);
    if (
      params.method === 'click' ||
      params.method === 'enter' ||
      params.method === 'type' ||
      params.method === 'escape'
    ) {
      _event.preventDefault();
      const matchingAddress = searchResults.find(i => i.label === params.newValue);
      addressField.props.onChange({ label: params.newValue, id: matchingAddress?.id ?? '' });
    }
  };

  const onSuggestionsFetchRequested = useCallback(
    debounce(async ({ value }: { value: string }) => {
      const data = await getSuggestions(value);
      setSearchResults(data);
    }, 250),
    []
  );

  const onSuggestionSelected = (event: FormEvent<any>, test: any) => {
    // since its from the suggestions, its valid
    setSelectedValidAddress({ label: test.suggestion.label, id: test.suggestion.id });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSearchResults([]);
    setSelectedValidAddress({label:"",id:""})
  };

  // if user has entered valid address before and then they change to another field but don't select a valid address
  // reset back to the valid one they did first time
  const onBlur = () => {
    if (selectedValidAddress && !checkValidAddress(addressField.value)) {
      addressField.setValue(selectedValidAddress);
    }
  };

  // ensure that we keep track of valid address selected
  useEffect(() => {
    // if one of field empty then its invalid
    // we set address field to null, if we want to prevent the always correct selected value
    if (addressField.value === null) {
      setSelectedValidAddress(addressField.value);
    } else if (checkValidAddress(addressField.value) && !selectedValidAddress) {
      setSelectedValidAddress(addressField.value);
    }
  }, [addressField.value, selectedValidAddress]);

  useEffect(() => {
    if (selectedAddress) {
      const matchingAddress = searchResults.find(i => i.label === selectedAddress);
      if (matchingAddress) {
        addressField.props.onChange({ label: selectedAddress, id: matchingAddress.id });
        setSelectedValidAddress({ label: selectedAddress, id: matchingAddress.id });
      } else {
        // Optional: fetch suggestions if not found
        const address = selectedAddress;
        const commaIndex = address.indexOf(',');
        const commaSeparatedAddress = commaIndex !== -1 ? address.slice(0, commaIndex) : address;
        const suggestions =  addressService.getAddress(commaSeparatedAddress, addressType);
        suggestions.then(newSuggestions => {
          setSearchResults(newSuggestions);
          const newMatchingAddress = newSuggestions.find(i => i.label === selectedAddress);
          if (newMatchingAddress) {
            addressField.props.onChange({ label: selectedAddress, id: newMatchingAddress.id });
            setSelectedValidAddress({ label: selectedAddress, id: newMatchingAddress.id });
          }
        });
      }
    }
  }, []);
 
  // Autosuggest will pass through all these props to the input.
  const autoSuggestProps = {
    placeholder: placeholder ?? '',
    value: addressField.value ? addressField.value.label : '',
    onChange: onChange,
    id: id,
    className: inputClassName,
    required: addressField.required,
    disabled: disabled,
    onBlur: onBlur,
  };
  let showIcons=window.location.href.includes('/build-my-plan/broadband');
  
  let icons= searchData || selectedAddress ?
     <svg style={{marginTop:'-38px'}} className="absolute top-12 right-1 cursor-pointer" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>{if(resetInstallationAddressField){setSearchResults([]);resetInstallationAddressField();setSearchData(0)}}}>
        <path d="M24.5 7.9L7.5 24.1" stroke="black" stroke-linecap="square"/>
        <path d="M7.9 7.5L24.1 24.5" stroke="black" stroke-linecap="square"/>
      </svg> 
     :  
      <div style={{marginTop: screen.width > 400 ? '-34px':'-28px'}}  className="absolute top-12 right-2 md:right-1  cursor-pointer"> <svg style={{width:screen.width>400? '25px':'20px'}} stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26"><circle cx="11.5" cy="11.5" r="10.5" fill="transparent" stroke-width="2"></circle><path d="M19.414 19L25 24.586" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></div> 
      
  return (
    <>
      <AutoSuggest
        suggestions={searchResults}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={autoSuggestProps}
      />
      {showIcons?icons:""}
       {/* {searchData  ?
      //  <img style={{marginTop:'-34px'}} className="absolute top-12 right-1" src={'https://static.sky.co.nz/sky/icons/icon-color-wifi.svg'} alt=""  onClick={()=>{if(resetInstallationAddressField){setSearchResults([]);resetInstallationAddressField();setSearchData(0)}}}/> 
       <svg style={{marginTop:'-38px'}} className="absolute top-12 right-1 cursor-pointer" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>{if(resetInstallationAddressField){setSearchResults([]);resetInstallationAddressField();setSearchData(0)}}}>
          <path d="M24.5 7.9L7.5 24.1" stroke="black" stroke-linecap="square"/>
          <path d="M7.9 7.5L24.1 24.5" stroke="black" stroke-linecap="square"/>
        </svg> 
       :  
        <div style={{marginTop:'-34px'}}  className="absolute top-12 right-1 cursor-pointer"> <svg width="25px" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26"><circle cx="11.5" cy="11.5" r="10.5" fill="transparent" stroke-width="2"></circle><path d="M19.414 19L25 24.586" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></div> 
        }  */}
     
      {errorPresent && !disabled && <p className={errorClassName}>{addressField.errors[0]}</p>}
    </>
  );
};

export { ValidatedAddressSuggest };